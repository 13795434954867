<script>
function validator(value) {
  return typeof value === 'string' || Array.isArray(value);
}

export default defineComponent({
  setup(props, ctx) {
    // Allow backwards compatability
    if (ctx.attrs.slots) {
      props = new Proxy(props, {
        get(target, prop) {
          if (prop === 'slot') {
              return ctx.attrs.slots;
          }

          return target[prop];
        }
      });
    }

    const currentInstance = getCurrentInstance();
    const slots = useSlots();

    const childComponents = computed(() => slots.default?.().map(
      component => withForwardedSlots(component, currentInstance, props)) || []
    );

    return () => childComponents.value.map(childComponent => h(childComponent));
  },

  name: "ForwardSlots",

  props: {
    slot: {
      type: [String, Array],
      default: () => [],
      validator,
    },

    exclude: {
      type: [String, Array],
      default: () => 'default',
      validator,
    }
  }
});

function withForwardedSlots(component, instance, options) {
  const include = normalizeToArray(options.slot);
  const exclude = normalizeToArray(options.exclude);

  const slots = resolveSlots(instance);

  const filteredSlots = Object.keys(slots)
    .filter(key => (! include.length || include.includes(key)) && (! exclude.length || ! exclude.includes(key)))
    .reduce((acc, key) => {
      acc[key] = args => slots[key](args);
      return acc;
    }, {});

  return h(component, options, filteredSlots);
}

function normalizeToArray(input) {
  if (Array.isArray(input)) return input;
  return input ? [input] : [];
}

function resolveSlots(instance) {
  const slots = { ...instance.slots };
  if (instance.parent) {
    Object.assign(slots, instance.parent.slots);
  }
  return slots;
}
</script>
